import { Injectable } from '@angular/core';
import { and, where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@models/collections';
import { Movement } from '@models/inscription';
import { LicenseType, LicenseUserRate } from '@models/license';
import { Gender, PaymentType } from '@models/masters';
import { Match } from '@models/match';
import { Team } from '@models/teams';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { SeasonsService } from './seasons.service';
import { UserGender } from '@models/user';
import { Club } from '@models/clubs';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly expenses$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'EXPENDITURE'))
    .pipe(shareReplay(1));

  private readonly incomes$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'INCOME'))
    .pipe(shareReplay(1));

  private readonly teams$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  private readonly clubs$ = this.firestore.listDocuments<Club>(COLLECTIONS.CLUBS).pipe(shareReplay(1));

  private readonly licenses$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocumentsGroup<LicenseUserRate>(
        COLLECTIONS.USERS_LICENSES,
        where('seasonYear', '==', seasonYear),
      ),
    ),
    shareReplay(1),
  );

  public adminStats$ = combineLatest([this.expenses$, this.incomes$, this.teams$, this.licenses$, this.clubs$]).pipe(
    map(([expenses, incomes, teams, licenses, clubs]) => {
      const totalExpenses = expenses.reduce((acc, curr) => acc + +curr.amount, 0);
      const totalIncomes = incomes.reduce((acc, curr) => acc + +curr.amount, 0);
      const incomesByType = incomes.reduce(
        (acc, income) => {
          const type = income.paymentType as PaymentType;
          if (type && typeof type === 'string') {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type] += +income.amount;
          }
          return acc;
        },
        {} as Record<PaymentType, number>,
      );

      const licensesByUserAndType = licenses.reduce((acc, license) => {
        const { userId, licenseType } = license;

        if (typeof userId !== 'string' || typeof licenseType !== 'string') {
          return acc;
        }

        if (!acc.has(userId)) {
          acc.set(userId, new Set<string>());
        }

        return acc;
      }, new Map<string, Set<string>>());

      const licensesByType = Array.from(licensesByUserAndType.values()).reduce(
        (acc, licenseTypesSet) => {
          licenseTypesSet.forEach((type) => {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type]++;
          });
          return acc;
        },
        {} as Record<string, number>,
      );

      const incomesLicensesByType = licenses.reduce(
        (acc, license) => {
          const licenseType = license.licenseType as LicenseType;
          const amount = license.price;

          if (!licenseType || typeof licenseType !== 'string' || !amount) {
            return acc;
          }

          if (!acc[licenseType]) {
            acc[licenseType] = 0;
          }
          acc[licenseType] += +amount;

          return acc;
        },
        {} as Record<LicenseType, number>,
      );

      const incomesLicensesByGender = licenses.reduce(
        (acc, license) => {
          const gender = license.gender as UserGender;
          const amount = license.price;

          if (!gender || typeof gender !== 'string' || !amount) {
            return acc;
          }

          if (!acc[gender]) {
            acc[gender] = 0;
          }
          acc[gender] += +amount;

          return acc;
        },
        {} as Record<UserGender, number>,
      );

      const expensesByClub = expenses.reduce(
        (acc, expense) => {
          const clubId = expense.clubId;
          const amount = expense.amount;

          if (!clubId || typeof clubId !== 'string' || !amount) {
            return acc;
          }

          const club = clubs.find((c) => c.id === clubId);
          const clubName = club ? club.name : 'Unknown Club';

          if (!acc[clubName]) {
            acc[clubName] = 0;
          }
          acc[clubName] += +amount;

          return acc;
        },
        {} as Record<string, number>,
      );

      const sortedExpensesByClub = Object.entries(expensesByClub)
        .sort(([, a], [, b]) => b - a)
        .reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          {} as Record<string, number>,
        );

      const uniqueUsersWithLicenses = licensesByUserAndType.size;

      return {
        totalExpenses,
        totalIncomes,
        netBalance: totalExpenses - totalIncomes,
        teamsCount: teams.length,
        uniqueUsersWithLicenses,
        licensesByType,
        incomesByType,
        incomesLicensesByType,
        incomesLicensesByGender,
        expensesByClub: sortedExpensesByClub,
      };
    }),
  );

  public allMatches$ = this.seasonsService.all$.pipe(
    switchMap((seasons) => {
      const seasonIds = seasons.map((season) => season.id);
      const chunkArray = (array: string[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, size + i));
        }
        return result;
      };

      const seasonsChunks = chunkArray(seasonIds, 30);
      return combineLatest(
        seasonsChunks.map((seasonChunk) =>
          this.firestore
            .listDocuments<Match>(COLLECTIONS.MATCH, and(where('seasonId', 'in', seasonChunk)))
            .pipe(map((matches) => matches)),
        ),
      ).pipe(map((results) => results.flat()));
    }),
    shareReplay(1),
  );

  public matchesSummary$ = combineLatest([this.allMatches$, this.seasonsService.all$, this.teams$]).pipe(
    map(([matches, seasons, teams]) => {
      const ongoingMatches = matches.filter((match) => match.status === 'IN-PROGRESS').length;

      const finishedMatches = matches.filter((match) => match.status === 'FINISHED').length;
      const pendingMatches = matches.filter((match) => match.status === 'PENDING').length;

      const matchesWithIncidents = matches.filter((match) => match.incident && match.incident !== null).length;

      const uniqueClubIds = new Set(teams.map((team) => team.clubId));
      const numOfClubs = uniqueClubIds.size;
      return {
        ongoingMatches,
        finishedMatches,
        matchesWithIncidents,
        pendingMatches,
        numOfCompetitions: seasons.length,
        numOfClubs,
      };
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private seasonManager: SeasonManagerService,
    private seasonsService: SeasonsService,
  ) {}
}
